import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';

import { DiagonalAngleWidth } from '../constants';
import { Breakpoint } from '../types';
import { forBreakpoint } from '../utils/forBreakpoint';

export function BackgroundLine() {
  return <StyledBackgroundLine />;
}

const StyledBackgroundLine = styled.div`
  position: fixed;
  z-index: 0;
  height: 109vh;
  background-color: #5d5d5d;
  width: 1px;
  opacity: 0.25;
  transform: rotate(15.8deg);
  top: -5%;
  right: ${DiagonalAngleWidth / 2.04}vh;
  overflow: hidden;

  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      display: none;
    `
  )};
`;
