import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import Img from 'gatsby-image/withIEPolyfill';
import React, { useEffect, useState } from 'react';

import { Text } from '../../components/UI/Text';
import { DesktopWidth, PolygonLeft, PolygonRight } from '../../constants';
import { Breakpoint, ProjectType } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { BackgroundLine } from '../BackgroundLine';
import { UnstyledLink } from '../UI/StyledLink';

import { ClientBox } from './ClientBox';

type Props = {
  project: ProjectType;
  direction: 'left' | 'right';
};

export function Project({ project, direction }: Props) {
  const [windowWidth, setWindowWidth] = useState<number>(0);
  function ResizeWindow() {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    ResizeWindow();
    window.addEventListener('resize', ResizeWindow);
    return () => window.removeEventListener('resize', ResizeWindow);
  }, []);

  const mobile = windowWidth <= DesktopWidth;
  const desktop = windowWidth > DesktopWidth;

  return (
    <>
      <BackgroundLine />
      {desktop && (
        <ItemDesktop direction={direction}>
          <DesktopImage
            direction={direction}
            fluid={project.Thumb.asset.fluid}
            alt={project.Slug}
          />
          <ClientContainer direction={direction}>
            <ClientBox
              direction={direction}
              client={project.Client}
              slug={project.Slug}
            />
          </ClientContainer>
        </ItemDesktop>
      )}
      {mobile && (
        <Item>
          <ImageBox>
            <MobileImage
              direction={direction}
              fluid={project.Thumb.asset.fluid}
              alt={project.Slug}
            />
          </ImageBox>
          <Description>
            <ClientName mode="subtitle">{project.Client}</ClientName>
            <ViewLink to={`/project/${project.Slug}`}>View Project</ViewLink>
          </Description>
        </Item>
      )}
    </>
  );
}

const ItemDesktop = styled.div<{ direction: string }>`
  display: flex;
  flex-direction: ${props =>
    props.direction === 'left' ? 'row' : 'row-reverse'};
`;

const Item = styled.div`
  margin-bottom: 1rem;
`;

const ImageBox = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 1rem;
  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      background-size: contain;
    `
  )}
`;

const Description = styled.div`
  margin-left: 1rem;
  margin-right: 3rem;
  margin-bottom: 2.5rem;
`;

const ClientName = styled(Text)`
  display: block;
  margin-bottom: 1rem;
  line-height: 1.2;
  font-weight: 400;
`;

const MobileImage = styled(Img)<{ direction: string }>`
  width: 100%;
  height: 100%;
  min-height: 295px;
  clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);

  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      clip-path: polygon(0 3%, 100% 0, 100% 97%, 0% 100%);
    `
  )}
`;

const DesktopImage = styled(Img)<{ direction: string }>`
  width: 71.5%;
  height: 100%;
  min-height: 495px;
  clip-path: ${props =>
    props.direction === 'left' ? PolygonLeft : PolygonRight};
`;

const ViewLink = styled(UnstyledLink)`
  border-bottom: 1px solid black;
`;

const ClientContainer = styled.div<{ direction: string }>`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  align-items: ${props =>
    props.direction === 'left' ? 'flex-start' : 'flex-end'};
`;
