import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React, { Fragment } from 'react';

import { Breakpoint, ProjectType } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Project } from '../Projects/Project';
import { UnstyledLink } from '../UI/StyledLink';
import { Text } from '../UI/Text';

import GreaterThanLogo from './greaterThan.svg';

type Props = {
  projects: ProjectType[];
  title: string;
};

export function ProjectList({ title, projects }: Props) {
  return (
    <SplashProjectsContainer>
      <Projects mode="subtitle">{title}</Projects>
      <AllProjectsDesktopContainer>
        <Text mode="logo">
          <UnstyledLink to="/projects">
            ALL PROJECTS
            <GreaterThan src={GreaterThanLogo} alt="More" />
          </UnstyledLink>
        </Text>
      </AllProjectsDesktopContainer>
      <MappedProjects>
        {projects.map((project, index) => {
          return (
            <Fragment key={index}>
              {index % 2 === 0 ? (
                <Project project={project} direction="left" />
              ) : (
                <Project project={project} direction="right" />
              )}
            </Fragment>
          );
        })}
      </MappedProjects>
    </SplashProjectsContainer>
  );
}

const SplashProjectsContainer = styled.div`
  position: relative;
  max-width: 2000px;
  margin: 0 auto;
  padding-top: 6rem;
  background-color: white;

  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      max-width: 100%;
      padding-top: 14rem;
    `
  )};
`;

const Projects = styled(Text)`
  margin-bottom: 2rem;
  margin-left: 2.2rem;

  ${forBreakpoint(
    Breakpoint.Phone,
    css`
      margin-left: 1rem;
    `
  )}
  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      margin-bottom: 6rem;
      margin-left: 2rem;
    `
  )}
`;

const GreaterThan = styled.img`
  margin-left: 0.7rem;
  width: 0.5rem;
  padding-top: 4px;

  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      width: 0.45rem;
    `
  )}

  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      width: 1.1rem;
      margin-left: 2.1rem;
    `
  )}
`;

const AllProjectsDesktopContainer = styled.div`
  display: none;
  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      display: inline;
      float: right;
      margin-right: 2rem;
      :hover {
        border-bottom: 1px solid gray;
        margin-bottom: -1px;
      }
    `
  )};
  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      margin-right: 6rem;
    `
  )}
`;

const MappedProjects = styled.div`
  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
    display: flex;
    flex-direction: column;
    }
    `
  )};
`;
