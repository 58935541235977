import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { Link } from 'gatsby';
import React from 'react';

import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { inBrowser } from '../../utils/inBrowser';
import { Button } from '../UI/Button';
import { Text } from '../UI/Text';

type Props = {
  direction: 'left' | 'right';
  client: string;
  slug: string;
};

export function ClientBox({ direction, client, slug }: Props) {
  return (
    <Box direction={direction}>
      <ClientName mode="subtitle">{client}</ClientName>
      <div>
        <ViewProject to={`/project/${slug}`}>
          <Text mode="underline">View Project</Text>
        </ViewProject>
        <PlayButton mode="primary">
          <Link
            onClick={() => {
              if (inBrowser()) {
                localStorage.setItem(slug, 'true');
              }
            }}
            to={`/project/${slug}`}
          >
            <Text mode="buttonSecondary">Play</Text>
          </Link>
        </PlayButton>
      </div>
    </Box>
  );
}

const Box = styled.div<{ direction: string }>`
  display: flex;
  flex-direction: column;
  @supports (-ms-ime-align: auto) {
    margin: 0 2rem;
  }
  align-items: ${props =>
    props.direction === 'left' ? 'flex-start' : 'flex-end'};
`;

const PlayButton = styled(Button)`
  display: inline-block;
  background-color: white;
`;

const ViewProject = styled(Link)`
  text-decoration: none;
  margin-right: 1rem;
  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      margin-right: 3rem;
    `
  )}
`;

const ClientName = styled(Text)`
  display: block;
  margin-bottom: 1rem;
  line-height: 1.2;
  font-weight: 400;
  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      margin-bottom: 3rem;
    `
  )}
`;
